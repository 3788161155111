import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js";

export default {
	init () {

		Fancybox.defaults.Hash = false;

    //$.fancybox.defaults.Thumbs = { autoStart : true };
    Fancybox.defaults.Thumbs = { autoStart : false };
    
    Fancybox.bind("[data-fancybox]", {
      Thumbs: {
        autoStart: false,
      },
    });

    Fancybox.bind('[data-fancybox*="team"]', {
      Thumbs: {
        autoStart: false,
      }
    });


    Fancybox.bind('[data-fancybox*="award"]', {
      Thumbs: {
        autoStart: false,
      }
    });

    
    
    Fancybox.bind('[data-fancybox="styles"]', {
      Thumbs: {
        autoStart: false,
      },
      Toolbar: {
        display: [
          "close",
        ],
      },
    });

	},
}