/**
 * External dependencies
 */
// import 'bootstrap';
import { Carousel } from 'bootstrap';
import '@accessible360/accessible-slick';
import 'img-comparison-slider';

import 'what-input';
import Isotope from 'isotope-layout';
import 'isotope-packery';

import jqueryMatchHeight from 'jquery-match-height';

// import "jquery.scrollTo";

/**
 * Internal dependencies
 */
import plyr from './modules/plyr';

import fancyBox from './modules/fancybox';

import menu from './modules/menu';

import balancetext from 'balance-text';

$(function() {

	fancyBox.init();

	plyr.init();

	menu.init();

	/* $('p').addClass('balance-text');

	balanceText($('p'), {watch: true}); */

/*
	var $grid = $('.block-16-team .grid').masonry({
		itemSelector: '.grid-item',
		columnWidth: '.grid-sizer',
  		percentPosition: false
	});
	
	$grid.imagesLoaded().progress( function() {
		$grid.masonry('layout');
	});
	*/


	/* var $grid = $('.block-16-team .grid').isotope({
		layoutMode: 'packery',
		itemSelector: '.grid-item',
		percentPosition: true,
		packery: {
		  columnWidth: '.grid-sizer'
		}
	  }); */

	

	  var grids = document.querySelectorAll('.block-16-team .grid');

	  grids.forEach(function (grid, index) {

		var iso = new Isotope( grid, {
			layoutMode: 'packery',
			itemSelector: '.grid-item',
			percentPosition: true,
			packery: {
			columnWidth: '.grid-sizer'
			}
		});
		
	});


	$(window).on('resize', function () {
		slickChangePos();
	});

	$('.slick').on('init breakpoint', function(event, slick){
		slickChangePos();
	});

	$('.slick').slick();

	function slickChangePos() {
		setTimeout(function() { 
			$('.slick').each(function(key, item) {
				let height = ($('.slick-active', $(this)).find('.thumb').height());
				if(height) {
					$('.slick-arrow', $(this)).css('height', height + 'px');
				} else {
					let height = ($('.slick-active', $(this)).find('.background-image').height());

					if(height) {
						$('.slick-arrow', $(this)).css('height', height + 'px');
					}
				}
			});			
		}, 100);
	}

	// Deprecated Gallery slider with built in before/after
	$('#slider').on('click', '.toggle-photo', function (e) {
		$(this).parent().find('img').toggle();
		$(this).find('span').toggle();
		e.preventDefault();
	});


	$(document).ready(function(){
		$('a[href^="#"]').on('click',function (e) {
			e.preventDefault();
			var target = this.hash;
			var $target = $(target);

			if( $target.length == 0) {
				return false;
			}

			$('html, body').stop().animate({
				'scrollTop': $target.offset().top
			}, 300, 'linear', function () {
				// window.location.hash = target;
			});
		});
	});


})