/**
 * File navigation.js.
 *
 * Handles toggling the navigation menu for small screens and enables TAB key
 * navigation support for dropdown menus.
 */

export default {
    init() {


        /*

An accessible menu for WordPress
https://github.com/argenteum/accessible-nav-wp

Licensed GPL v.2 (http://www.gnu.org/licenses/gpl-2.0.html)

This work derived from:
https://github.com/WordPress/twentysixteen (GPL v.2)
https://github.com/wpaccessibility/a11ythemepatterns/tree/master/menu-keyboard-arrow-nav (GPL v.2)

*/

        (function ($) {

            var menuContainer = $('.site-header');
            var menuItem = menuContainer.find('.menu-item-has-children a[href="#"]');
            var menuToggle = menuContainer.find('.menu-button');
            var siteHeaderMenu = menuContainer.find('.navbar');
            var siteNavigation = menuContainer.find('#site-navigation');

            // If you are using WordPress, and do not need to localise your script, or if you are not using WordPress, then uncomment the next line
            var screenReaderText = { "expand": "Expand child menu", "collapse": "Collapse child menu" };

            var dropdownToggle = $('<button />', { 'class': 'dropdown-toggle', 'aria-expanded': false })
                .append($('<span />', { 'class': 'screen-readers', text: screenReaderText.expand }));


            $(window).on("load resize",function(e){
                menuToggle.attr('aria-expanded', 'false');
                menuToggle.removeClass('toggled-on');
                siteHeaderMenu.removeClass('toggled-on');
            });
            
                // Toggles the menu button
            (function () {

                if (!menuToggle.length) {
                    return;
                }

                menuToggle.add(siteNavigation).attr('aria-expanded', 'false');

                menuToggle.on('click', function () {
                    
                    $(this).add(siteHeaderMenu).toggleClass('toggled-on');

                    // jscs:disable
                    $(this).add(siteNavigation)
                        .attr('aria-expanded', $(this)
                            .add(siteNavigation).attr('aria-expanded') === 'false' ? 'true' : 'false');
                    // jscs:enable

                });

                menuItem.on('click', function (e) {
                    e.preventDefault();

                    console.log('found');

                    if (Modernizr.touchevents) {
                        $(this).next('.dropdown-toggle').trigger('click');
                    }

                    
                });
            })();

            // Adds the dropdown toggle button
            $('.menu-item-has-children > a').after(dropdownToggle);

            // Adds aria attribute
            siteHeaderMenu.find('.menu-item-has-children').attr('aria-haspopup', 'true');

            // Toggles the sub-menu when dropdown toggle button clicked
            siteHeaderMenu.find('.dropdown-toggle').on('click', function (e) {

                if (!Modernizr.touchevents) {
                    // return;
                }

                var screenReaderSpan = $(this).find('.screen-readers');

                e.preventDefault();

                // Remove all other toggles
                /* siteHeaderMenu.find('.dropdown-toggle').not(this).removeClass('toggled-on');
                var $not = $(this).parent().closest('li').add( $(this) );
                console.log($not);
                siteHeaderMenu.find('.dropdown-toggle').not($not).next('.sub-menu').removeClass('toggled-on');
                siteHeaderMenu.find('.dropdown-toggle').not(this).attr('aria-expanded','false'); */

                $(this).parent().siblings().find('.dropdown-toggle').not(this).removeClass('toggled-on');
                $(this).parent().siblings().find('.dropdown-toggle').next('.sub-menu').removeClass('toggled-on');
                $(this).parent().siblings().find('.dropdown-toggle').attr('aria-expanded','false');

                $(this).toggleClass('toggled-on');
                $(this).next('.sub-menu').toggleClass('toggled-on');

                // jscs:disable
                $(this).attr('aria-expanded', $(this).attr('aria-expanded') === 'false'
                    ? 'true' : 'false');
                // jscs:enable
                screenReaderSpan.text(screenReaderSpan.text() ===
                    screenReaderText.expand ? screenReaderText.collapse :
                    screenReaderText.expand);
            });

            // Adds a class to sub-menus for styling
            $('.sub-menu .menu-item-has-children').parent('.sub-menu').addClass('has-sub-menu');


            // Keyboard navigation
            $('.menu-item a, button.dropdown-toggle').on('keydown', function (e) {

                var key = e.key || e.keyCode;

                const allowedKeys = [37, 'ArrowLeft', 38, 'ArrowUp', 39, 'ArrowRight', 40, 'ArrowDown'];

                if (allowedKeys.indexOf(key) == -1) {
                    return;
                }

                switch (key) {

                    case 37: 				// left key
                    case 'ArrowLeft':
                        e.preventDefault();
                        e.stopPropagation();

                        if ($(this).hasClass('dropdown-toggle')) {
                            $(this).prev('a').trigger('focus');
                        }
                        else {

                            if ($(this).parent().prev().children('button.dropdown-toggle').length) {
                                $(this).parent().prev().children('button.dropdown-toggle').trigger('focus');
                            }
                            else {
                                $(this).parent().prev().children('a').trigger('focus');
                            }
                        }

                        if ($(this).is('ul ul ul.sub-menu.toggled-on li:first-child a')) {
                            $(this).parents('ul.sub-menu.toggled-on li').children('button.dropdown-toggle').trigger('focus');
                        }

                        break;

                    case 39: 				// right key
                    case 'ArrowRight':
                        e.preventDefault();
                        e.stopPropagation();

                        if ($(this).next('button.dropdown-toggle').length) {
                            $(this).next('button.dropdown-toggle').trigger('focus');
                        }
                        else {
                            $(this).parent().next().children('a').trigger('focus');
                        }

                        if ($(this).is('ul.sub-menu .dropdown-toggle.toggled-on')) {
                            $(this).parent().find('ul.sub-menu li:first-child a').trigger('focus');
                        }

                        break;


                    case 40: 				// down key
                    case 'ArrowDown':
                        e.preventDefault();
                        e.stopPropagation();

                        if ($(this).next().length) {
                            $(this).next().find('li:first-child a').first().trigger('focus');
                        }
                        else {
                            $(this).parent().next().children('a').trigger('focus');
                        }

                        if (($(this).is('ul.sub-menu a')) && ($(this).next('button.dropdown-toggle').length)) {
                            $(this).parent().next().children('a').trigger('focus');
                        }

                        if (($(this).is('ul.sub-menu .dropdown-toggle')) && ($(this).parent().next().children('.dropdown-toggle').length)) {
                            $(this).parent().next().children('.dropdown-toggle').trigger('focus');
                        }

                        break;


                    case 38: 				// up key
                    case 'ArrowUp':
                        e.preventDefault();
                        e.stopPropagation();

                        if ($(this).parent().prev().length) {
                            $(this).parent().prev().children('a').trigger('focus');
                        }
                        else {
                            $(this).parents('ul').first().prev('.dropdown-toggle.toggled-on').trigger('focus');
                        }

                        if (($(this).is('ul.sub-menu .dropdown-toggle')) && ($(this).parent().prev().children('.dropdown-toggle').length)) {
                            $(this).parent().prev().children('.dropdown-toggle').trigger('focus');
                        }

                        break;

                }
            });
        })(jQuery);


    },
}
